<template>
    <div>
        <aw-tabler :table="table">
            <template #filter>
                <el-form ref="formRef" :model="table.filter" :inline="true">
                    <el-form-item label="正常数据:">
                        <el-input v-model="table.filter.keywords" placeholder="请输入" clearable></el-input>
                    </el-form-item>
                    <el-form-item label="状态:">
                        <el-select v-model="table.filter.status" placeholder="请选择">
                            <el-option v-for="(item, index) in dict.example.example_status" :key="index" :label="item" :value="index" />
                        </el-select>
                    </el-form-item>
                    <el-form-item label="手机号:">
                        <aw-select-remote
                            v-model="table.filter.mobile"
                            :select_info="{
                                axios_type: 'post',
                                axios_url: '/admin/shopUser/getShopList',
                                axios_params: { key_value: 'name', other_value: {} },
                                key_label_value: { label: 'username', value: 'id' }
                            }"
                            @change-data="selectChangeEvent"
                        />
                    </el-form-item>
                    <el-form-item label="时间范围:">
                        <aw-date-picker
                            v-model="table.filter.time"
                            :SelectTimesCustom="back_SelectTimesCustom"
                            :Date_Disabled="true"
                            :Button_Change="{ chat: '请选择', type: 'daterange' }"
                            :Out_Time="{ time_size: 8035200, erro_message: '选中的时间差不能超过 93 天' }"
                            @change-data="changeDataSearchEvent"
                        />
                    </el-form-item>
                </el-form>
            </template>
            <template #drawercontent> 弹窗的内容（使用自定义组件） </template>
        </aw-tabler>
    </div>
</template>
<script setup lang="jsx">
import { http, util, dict } from '@/assets/js'
import { reactive, ref } from 'vue'
import { useRoute } from 'vue-router'
import { tableColumn } from '@/assets/js/extend/tableColumn'
import { ElMessage } from 'element-plus'

// 操作权限
const powerlimits = reactive(JSON.parse(localStorage.getItem('powerlimits')))

// 选中时间
const back_SelectTimesCustom = ref('')
const changeDataSearchEvent = (data) => {
    back_SelectTimesCustom.value = [data[0] * 1000, data[1] * 1000]
    table.filter.time = data[0] * 1000 + ' ~ ' + data[1] * 1000
}

// 远程select选中
const selectChangeEvent = (data) => {
    table.filter.mobile = data
}

const route = useRoute()
const table = reactive({
    title: '样例表',
    select: true,
    loading: false,
    filter: {
        keywords: '',
        status: '',
        mobile: '',
        time: ''
    },
    datas: [
        {
            id: '39992',
            normal: '正常数据显示\n多行数据可以使用斜杠n',
            custom: '',
            route: '测试route跳转',
            status: 1,
            tags: '多个tag用数组,单个tag可单个字符串',
            time: '1698402203',
            image: 'https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg,https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg'
        }
    ],
    columns: [
        ...tableColumn([
            {
                title: '正常数据',
                key: 'normal',
                minWidth: '100px'
            },
            {
                title: '自定义数据',
                key: 'custom',
                minWidth: '100px',
                render: (data) => {
                    return (
                        <div>
                            <span>
                                id:{data.id + '\n'}换行后的数据:{data.route + '\n'}
                            </span>
                            <el-button type={'primary'} size={'small'}>
                                自定义button
                            </el-button>
                        </div>
                    )
                }
            },
            {
                title: '跳转',
                key: 'route',
                minWidth: '100px',
                props: (data) => ({ disabled: data.id == 39992 && powerlimits.azjl }),
                route: (data) => '/userlist?id=' + data.id
            },
            {
                title: '是否启用',
                key: 'status',
                minWidth: '100px',
                props: (data) => ({ disabled: data.id != 39992 }),
                confirm: true,
                switch: ({ id, status }) => http.post('/admin/shopUser/disable', { id, status })
            },
            {
                title: 'tag的使用',
                key: 'tags',
                width: 400
                // tag: (data) => {
                //     let tags = data.tags.split(',')
                //     return tags.map((n) => {
                //         return { text: n, props: { type: 'primary' } }
                //     })
                // }
            },
            { title: '时间戳转化', key: 'time', minWidth: '100px', time: true },
            { title: '图片', key: 'image', minWidth: '100px', image: true }
        ]),
        {
            fixed: 'right',
            title: '操作',
            key: 'operate',
            minWidth: '160px',
            buttons: [
                {
                    name: '查看',
                    props: { type: 'primary', size: 'small' },
                    show: (data) => data.id == 39992 && powerlimits.azjl,
                    action: (data) => {
                        table.drawer.show = true
                        table.drawer.title = '查看'
                        console.log('查看：', data)
                    }
                },
                {
                    name: '编辑',
                    props: { type: 'primary', size: 'small' },
                    action: (data) => {
                        table.drawer.show = true
                        table.drawer.title = '编辑'
                        console.log('编辑：', data)
                    }
                },
                {
                    name: '删除',
                    props: { type: 'danger', size: 'small' },
                    action: (data) => {
                        console.log('删除：', data)
                    }
                }
            ]
        }
    ],
    page: {
        total: 0,
        page: 1,
        page_size: 20,
        sizeChange: (val) => {
            table.page.page_size = val
            table.action(table.filter)
        },
        currentChange: (val) => {
            table.page.page = val
            table.action(table.filter)
        }
    },
    action: async (params) => {
        table.loading = true
        let page = { page: table.page.page, page_size: table.page.page_size }
        let result = await http.post('/admin/Member/lists', { ...params, ...route.query, ...page })
        table.datas = result.data
        table.page.total = result.total || Infinity
        table.page.page = result.current_page
        table.page.page_size = result.per_page
        table.loading = false
        return
    },
    extras: [
        {
            name: '下载Excel模板',
            props: { type: 'primary', size: 'small' },
            show: () => true,
            action: () => {
                wind.open('', '_blank')
                console.log('下载Excel模板：')
            }
        },
        {
            comp: 'aw-upload',
            mode: {
                uploadinfo: {
                    text: 'Excel批量添加',
                    size: 'small',
                    // 上传文件限制，0标识无限制
                    limit: 0,
                    // 是否支持多选
                    multiple: false,
                    // 非OSS直传
                    action: '/admin/upload/file?target_id=100',
                    headers: {
                        Authentication: localStorage.getItem('token')
                    },
                    // OSS直传（当为true时，非OSS直传将无效化）
                    httpRequest: false,
                    // 上传文件规则
                    rules: {
                        images: {
                            image_width: 0, //图片宽度，0表示不限宽
                            image_height: 0, //图片高度，0表示不限高
                            size: 1000, //文件大小（单位kb）
                            type: ['image/jpeg', 'image/jpg', 'image/gif', 'image/bmp', 'image/png'], //文件格式
                            suffix: ['jpeg', 'jpg', 'gif', 'bmp', 'png'], //文件后缀-用于判断
                            typechart: 'png/bmp/gif/jpg/jpeg' //文件格式提示规则
                        }
                        // files: {
                        //     size: 1000, //文件大小（单位kb）
                        //     type: [
                        //         'text/plain',
                        //         'application/pdf',
                        //         'application/msword',
                        //         'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                        //         'application/x-xls',
                        //         'application/vnd.ms-excel'
                        //     ], //文件格式
                        //     typechart: 'text/pdf/word/xls' //文件格式提示规则
                        // },
                        // video: {
                        //     size: 1000, //文件大小（单位kb）
                        //     type: ['video/mp4'], //文件格式
                        //     typechart: 'mp4' //文件格式提示规则
                        // },
                        // audio: {
                        //     size: 1000, //文件大小（单位kb）
                        //     type: ['audio/mpeg'], //文件格式
                        //     typechart: 'mp3' //文件格式提示规则
                        // }
                    }
                }
            },
            show: () => powerlimits.azjl,
            action: async (data) => {
                console.log('uploadsuccess:', data)
            }
        },
        {
            name: '添加',
            props: { type: 'primary', size: 'small' },
            show: () => true,
            action: () => {
                table.drawer.show = true
                table.drawer.title = '添加'
                console.log('添加：')
            }
        },
        {
            name: '导出',
            props: { type: 'warning', size: 'small' },
            action: () => {
                console.log('导出：')
            }
        }
    ],
    footers: [
        {
            name: '批量修改',
            props: { type: 'primary', size: 'small' },
            action: (data) => {
                if (!data.length) return ElMessage({ type: 'warning', message: '请至少选择一条数据，再批量修改!' })

                table.drawer.show = true
                table.drawer.title = '批量修改'
                console.log('批量修改：', data)
            }
        },
        {
            name: '批量删除',
            props: { type: 'danger', size: 'small' },
            action: async (data) => {
                if (!data.length) return ElMessage({ type: 'warning', message: '请至少选择一条数据，再批量删除!' })

                await util.confirm({
                    title: '批量删除',
                    action: () => {
                        console.log('批量删除：', data)
                    }
                })
            }
        }
    ],
    drawer: reactive({
        show: false,
        title: '',
        close: () => {
            table.drawer.show = false
        }
    })
})
</script>
<style scoped></style>
